import React from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import FooterFour from "../common/footer/FooterFour";
import BrandThree from "../elements/brand/BrandThree";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

const AboutUs = () => {
  return (
    <>
      <SEO
        title="About INZPIRO: Your Event Management Experts"
        description={
          "Discover INZPIRO, Chennai's premier event management company, specializing in weddings, birthdays, and unforgettable celebrations. We turn your dream events into reality with expert planning, stunning designs, and flawless execution. Learn more about our journey and services!"
        }
      />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">
                        About INZPIRO: Weddings, Birthdays & More
                      </span>
                    </h3>
                  </div>
                  <h1 className="title display-one">We are a INZPIRO EVENTS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title"> Welcome to INZPIRO Events</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Being the best event management company in Chennai, we offer
                  event management services in Chennai for weddings, birthdays,
                  and corporate events. We offer creativity, accuracy and
                  enthusiasm in all of our events. This is where we step in to
                  setups, design provide and delectable create food, the and
                  perfect help stage you create memories that last a lifetime.
                  At INZPIRO, attention to detail is the key. Contact us and get
                  ready to have the event of your dreams.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        {/* <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Brand Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What INZPIRO Offers You"
                  title="Services provide for you."
                  description="We provide high quality services since 2017."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        {/* <Separator /> */}

        {/* <AboutFour image="./images/about/contact-image.jpg" /> */}

        {/* Start Elements Area  */}
        <div className="rwt-counterup-area pb--100">
          <div className="container mt_dec--30">
            <CounterUpFour
              column="col-lg-3 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-4"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Featured on Leading Wedding Platforms"
                  title="Trusted by Top Wedding Platforms"
                  description="We are proudly featured on WedMeGood, WeddingWire, and Wedding Buzzzar, <br /> delivering excellence and unforgettable events."
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-left" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Elements Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div> */}
        {/* End Elements Area  */}

        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;

import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/logo/brandlogo/wedmegood.jpg",
    title: "Wedmegood",
    // description:
    //   "There are many variations variats  of passages of Lorem Ipsum available.",
    link: "https://www.wedmegood.com/profile/Inzpiro-Events-621255",
  },
  {
    image: "/images/logo/brandlogo/wedding-wire.jpg",
    title: "Wedding Wire",
    link: "https://www.weddingwire.in/wedding-photographers/inzpiro-events--e452400",

    // description:
    //   "Passages there are many variatin Lorem Ipsum available majority suffered.",
  },
  {
    image: "/images/logo/brandlogo/wedding-bazzar.jpg",
    title: "Wedding Bazzar",
    link: "https://www.weddingbazaar.com/wedding-photographers/chennai/inzpiro-events",

    // description:
    //   "Variations There are many of pass ages of Lorem Ipsum available the majority.",
  },
  {
    image: "/images/logo/brandlogo/google-business.jpg",
    title: "Google Business",
    link: "https://g.page/r/CauQE0HMRkanEAI/review",

    //     description:
    //       "Variations There are many of pass ages of Lorem Ipsum available the majority.",
  },
];
const ServiceTwo = ({ textAlign, cardStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`card-box ${cardStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <a href={val.link} target="_blank" rel="noopener noreferrer">
                    <img src={`${val.image}`} alt="card Images" />
                  </a>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <a
                      href={val.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></a>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                  <a
                    className="btn-default btn-small btn-border"
                    href={val.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Review Us
                  </a>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;

import React from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import FooterFour from "../common/footer/FooterFour";
import BrandThree from "../elements/brand/BrandThree";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import ServiceFive from "../elements/service/ServiceFive";
import FooterTwo from "../common/footer/FooterTwo";
import HeaderTwo from "../common/header/HeaderTwo";
import Copyright from "../common/footer/Copyright";
import FooterOne from "../common/footer/FooterOne";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO
        title="Privacy Privacy Policy | INZPIRO EVENTS - Event Photography & Videography Services"
        description={
          "Learn how INZPIRO EVENTS protects your privacy while providing professional event management, photography, and videography services. View our data collection, usage, and protection policies."
        }
      />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Privacy Policy."
                  title="Privacy Policy."
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#1c1c1cc3",
                padding: "40px",
                borderRadius: "20px",
              }}
            >
              <h6 className="text-2xl font-semibold mb-4">
                Last Updated: January 8, 2025
              </h6>

              <div className="space-y-6">
                <section>
                  <h4 className="text-xl font-semibold mb-3">
                    1. Introduction
                  </h4>
                  <p className="text-gray-700">
                    INZPIRO EVENTS ("we", "our", or "us") is committed to
                    protecting your privacy. This Privacy Policy explains how we
                    collect, use, disclose, and safeguard your information when
                    you use our event management, photography, and videography
                    services.
                  </p>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">
                    2. Information We Collect
                  </h4>
                  <p className="text-gray-700 mb-3">
                    We collect information that you provide directly to us,
                    including:
                  </p>
                  <ul className="list-disc pl-6 text-gray-700 space-y-2">
                    <li>
                      Contact information (name, email address, phone number,
                      mailing address)
                    </li>
                    <li>Event details and preferences</li>
                    <li>Images and videos captured during your event</li>
                    <li>Payment information</li>
                    <li>Communication preferences</li>
                  </ul>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">
                    3. How We Use Your Information
                  </h4>
                  <p className="text-gray-700 mb-3">
                    We use the collected information for:
                  </p>
                  <ul className="list-disc pl-6 text-gray-700 space-y-2">
                    <li>Providing and improving our services</li>
                    <li>Communicating with you about your events</li>
                    <li>Processing payments</li>
                    <li>Personalizing your experience</li>
                    <li>
                      Marketing and advertising purposes, including Google Ads
                    </li>
                    <li>Legal compliance and protecting our rights</li>
                  </ul>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">
                    4. Information Sharing
                  </h4>
                  <p className="text-gray-700">
                    We may share your information with service providers,
                    business partners, and third parties (including Google Ads)
                    to help us provide and improve our services. We do not sell
                    your personal information to third parties.
                  </p>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">5. Your Rights</h4>
                  <p className="text-gray-700 mb-3">You have the right to:</p>
                  <ul className="list-disc pl-6 text-gray-700 space-y-2">
                    <li>Access your personal information</li>
                    <li>Correct inaccurate information</li>
                    <li>Request deletion of your information</li>
                    <li>Opt-out of marketing communications</li>
                    <li>Withdraw consent for data processing</li>
                  </ul>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">
                    6. Data Security
                  </h4>
                  <p className="text-gray-700">
                    We implement appropriate technical and organizational
                    measures to protect your personal information. However, no
                    method of transmission over the Internet is 100% secure.
                  </p>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">
                    7. Cookies and Tracking
                  </h4>
                  <p className="text-gray-700">
                    We use cookies and similar tracking technologies to improve
                    your experience and collect usage data. This includes Google
                    Analytics and Google Ads tracking. You can control cookie
                    preferences through your browser settings.
                  </p>
                </section>

                <section>
                  <h4 className="text-xl font-semibold mb-3">8. Contact Us</h4>
                  <p className="text-gray-700">
                    If you have questions about this Privacy Policy or our
                    practices, please contact us at:
                    <br />
                    INZPIRO EVENTS
                    <br />
                    28/3, selva vinayagar kovil street, old
                    washermenpet,chennai-600021
                    <br />
                    Email: support@inzpiro.in
                    <br />
                    Phone: +91 7358592625
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default PrivacyPolicy;

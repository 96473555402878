import React from "react";
import {
  FiAlertCircle,
  FiAward,
  FiBookOpen,
  FiCamera,
  FiCameraOff,
  FiCast,
  FiCheck,
  FiFile,
  FiFilm,
  FiPieChart,
  FiVideo,
} from "react-icons/fi";
import Separator from "../separator/Separator";

const PricingTwo = () => {
  return (
    <div className="row row--15">
      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-2">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Bronze</h4>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">₹</span>
                  <span className="price">49,999</span>
                </div>
                <span className="subtitle">
                  <FiAlertCircle /> Customizable
                </span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li>
                  <FiCamera color="#e33fa1" /> Traditional + Candid Photography
                </li>
                <li>
                  <FiVideo color="#e33fa1" /> Traditional Videography
                </li>
                <li>
                  <FiBookOpen color="#e33fa1" /> 2 Album 600 pics with bag
                </li>
                <li>
                  <FiFilm color="#e33fa1" /> 4K Highlight & Video
                </li>
                <li>
                  <FiFile color="#e33fa1" /> 64gb Pendrive
                </li>
                <Separator></Separator>
                <span className="subtitle">
                  <FiAlertCircle /> Compliment
                </span>
                <Separator></Separator>
                <li>
                  <FiAward color="#FB5343" /> Pre or Post wedding Shoot
                </li>
                <li>
                  <FiAward color="#FB5343" /> calender, E-Invitation
                </li>
                <li>
                  <FiAward color="#FB5343" /> 4K Reels, Frame (3)
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a
                className="btn-default btn-border"
                href="https://wa.me/7358592625"
                target="_blank"
                rel="canonical" 
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-2 active">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Gold</h4>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">₹</span>
                  <span className="price">64,999</span>
                </div>
                <span className="subtitle">
                  <FiAlertCircle /> Customizable
                </span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li>
                  <FiCamera color="#e33fa1" /> 1-Traditional + Candid
                  Photography
                </li>
                <li>
                  <FiVideo color="#e33fa1" /> 1-Traditional + Candid Videography
                </li>
                <li>
                  <FiBookOpen color="#e33fa1" /> 2 Album (600) pics + suitcase
                </li>
                <li>
                  <FiFilm color="#e33fa1" /> 4K Highlight & Video
                </li>
                <li>
                  <FiFile color="#e33fa1" /> 128gb Pendrive
                </li>
                <Separator></Separator>
                <span className="subtitle">
                  <FiAlertCircle /> Compliment
                </span>
                <Separator></Separator>
                <li>
                  <FiAward color="#FB5343" /> Pre or Post wedding Shoot
                </li>
                <li>
                  <FiAward color="#FB5343" /> calender, E-Invitation
                </li>
                <li>
                  <FiAward color="#FB5343" /> 4K Reels, Frame (3)
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a className="btn-default"  href="https://wa.me/7358592625">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-2">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Platinum</h4>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">₹</span>
                  <span className="price">99,999</span>
                </div>
                <span className="subtitle">
                  <FiAlertCircle /> Customizable
                </span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li>
                  <FiCamera color="#e33fa1" /> 1-Traditional Photography
                </li>
                <li>
                  <FiVideo color="#e33fa1" /> 1-Traditional Videography
                </li>
                <li>
                  <FiCamera color="#e33fa1" /> 1-Candid Photography
                </li>
                <li>
                  <FiVideo color="#e33fa1" /> 1-Candid Videography
                </li>
                <li>
                  <FiBookOpen color="#e33fa1" /> 2 Album 700 pics + suitcase
                </li>
                <li>
                  <FiFilm color="#e33fa1" /> 4K Highlight & Video
                </li>
                <li>
                  <FiFile color="#e33fa1" /> 128gb Pendrive
                </li>
                <Separator></Separator>
                <span className="subtitle">
                  <FiAlertCircle /> Compliment
                </span>
                <Separator></Separator>
                <li>
                  <FiAward color="#FB5343" /> Pre or Post wedding Shoot
                </li>
                <li>
                  <FiAward color="#FB5343" /> calender, E-Invitation
                </li>
                <li>
                  <FiAward color="#FB5343" /> 4K Reels, Frame (4)
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a
              
                className="btn-default btn-border"
                href="https://wa.me/7358592625"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}
    </div>
  );
};
export default PricingTwo;
